import {
  generateUTMParametersDict,
  storeLastTouchUTMParametersInSessionCookie,
} from '@lib/analytics/track-utm'
import { LOCAL_STORAGE_UTM_PARAMS_KEY } from '@lib/constants/tracking'
import { setToLocalStorage } from '@lib/utils/local-storage'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * This is a workaround-hook for explicitly saving UTM params client-side.
 * Since enabling static generation on any NextJS page does not provide access to the query-params server-side.
 */
const useStoreUTMParams = () => {
  const router = useRouter()

  useEffect(() => {
    const utmParametersDict = generateUTMParametersDict(router)
    if (utmParametersDict)
      setToLocalStorage(
        LOCAL_STORAGE_UTM_PARAMS_KEY,
        JSON.stringify(utmParametersDict),
        () => storeLastTouchUTMParametersInSessionCookie(router)
      )
  }, [router.query])
}

export default useStoreUTMParams
